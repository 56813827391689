import React from 'react';

const Body = () => (
  <>
    <div className='bg-white'>
      <div className='container mx-auto py-16 px-8'>

        <div className='flex'>

          <div className=''>

            <p className='font-soft font-semibold text-lg text-grey-500 leading-snug mb-6'>

            </p>

            <p className='font-sans text-grey mb-4'>
              The NL Genome Project (the “Study”) is a research study of the unique genetic makeup of Newfoundland and Labrador. We hope to discover better, safer medicines and improve how we treat and prevent diseases.
            </p>

            <p className='font-sans text-grey mb-4'>
              This Privacy Policy describes how information, including personal information,  is collected, used, and shared when you visit www.nlgenomeproject.ca (the “Site”). Personal information means any information about an identifiable individual, or information that in combination with other easily accessible information, can identify an individual.
            </p>

            <p className='font-sans text-grey mb-4'>
              We have a separate Privacy Statement that outlines in detail how we collect, use and share information of participants who agree to be a part of the Study. If you are a participant, or you are considering participating, you can request the statement <a href="mailto: privacy@sequencebio.com" className="text-blue-500 underline">here</a>.
            </p>

            <h3 className='font-sans text-xl lg:text-2xl text-blue mb-4'>
              It’s up to you
            </h3>

            <p className='font-sans text-grey mb-4'>
              We only collect, use, and disclose your personal information with your consent, and only as outlined in this Privacy Policy. We will always be upfront and clear about why we are collecting this information and what it will be used for so you can make an informed decision. If you have any questions about our privacy practices or your personal information, please contact our Privacy Officer at privacy@sequencebio.com.
            </p>

            <h3 className='font-sans text-xl lg:text-2xl text-blue mb-4'>
              Collection and use of information
            </h3>

            <h3 className='font-sans text-xl font-bold text-grey mb-4'>
              Device Information
            </h3>

            <p className='font-sans text-grey mb-4'>
              When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages that you view on the Site, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”
            </p>

            <h3 className='font-sans text-lg font-bold text-grey mb-4'>
              We collect Device Information using the following technologies:
            </h3>

            <ul className='ml-12 font-sans text-grey list-disc mb-4'>
              <li className='mb-2'>
                 “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier.
                 <ul className='ml-12 font-sans text-grey list-disc mb-4'>
                    <li className='mb-2'>
                      If you wish, you may disable cookies in your browser or delete them. While the Site does not require the use of cookies, some functionality may be disabled and your web surfing experience may be degraded.
                    </li>
                 </ul>
              </li>

              <li className='mb-2'>
                “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
              </li>
              <li className='mb-2'>
                “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
              </li>
            </ul>

            <h3 className='font-sans text-lg font-bold text-grey mb-4'>
              Use of Device Information
            </h3>

            <p className='font-sans text-grey mb-4'>
              We use the Device Information that we collect (in particular, your IP address) to help us screen for potential risk and fraud, and more generally to improve and optimize our Site (for example, by generating analytics about how users browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).
            </p>

            <p className='font-sans text-grey mb-4'>
              We use technologies to automatically record some general information about our website usage (such as number of visitors and pageviews), which we use to prepare reports on website activities to help make the site more useful to visitors. These technologies collect and use anonymized information and data, not personal information.
            </p>

            <h3 className='font-sans text-xl font-bold text-grey mb-4'>
              Other collected information - Analytics and third party collection
            </h3>

            <p className='font-sans text-grey mb-4'>
              We use Google Analytics to track data about a visitor's website session. It does not know who is conducting the session (IP address is the identifier), and tracks data about on-site activity like the number of pages visited and the time on each page
            </p>

            <p className='font-sans text-grey mb-4'>
              You can read more about how Google uses your personal information here:  https://www.google.com/intl/en/policies/privacy/.
  You can also opt-out of Google Analytics here:  https://tools.google.com/dlpage/gaoptout.
            </p>

            <h3 className='font-sans text-lg font-bold text-grey mb-4'>
              Information you provide to us
            </h3>

            <p className='font-sans text-grey mb-4'>
              You do not need to provide your email address to access the Site. However, we ask for your name and email address if you wish to receive NL Genome Project news, press releases, and other research related news. You may also provide us with the name of your doctor. Your name and email address, and doctor’s name, are not stored together or linked. Providing this information is voluntary. You may also provide us with the name of your doctor. Your name and email address, and doctor’s name, are not stored together or linked. Providing this information is voluntary. You can also easily opt-out of these messages by selecting the unsubscribe option in any electronic communication from us, or by sending an email to hi@nlgenomeproject.ca.
            </p>

            <p className='font-sans text-grey mb-4'>
              We use a third party provider called MailChimp to help us manage communications (emails and newsletters) for individuals who have subscribed to receive them.  For more on their information collection and privacy policies, please see https://mailchimp.com/legal/privacy.
            </p>

            <h3 className='font-sans text-xl lg:text-2xl text-blue mb-4'>
              Communications
            </h3>

            <p className='font-sans text-grey mb-4'>
              Any general inquiries that you send to hi@nlgenomeproject.ca will be collected by us, and may contain information you deem sensitive or private.
            </p>

            <h3 className='font-sans text-xl lg:text-2xl text-blue mb-4'>
              Unsolicited personal health information
            </h3>

            <p className='font-sans text-grey mb-4'>
              <span className='font-bold'>We kindly ask that you do not send us unsolicited personal health information.</span><br />
  If you contact Sequence Bio, the NL Genome Project, or a team member by email/phone with a question or comment that includes personal health information (of you or another individual), we will delete the email/voicemail in order to protect your privacy. We do not retain any emails with personal information for more than two weeks. We are of the view that email is not a secure means by which personal health information should be transmitted.

            </p>

            <h3 className='font-sans text-xl lg:text-2xl text-blue mb-4'>
              Sharing your personal information
            </h3>

            <p className='font-sans text-grey mb-4'>
              We will only share or disclose your personal information as outlined in this Policy.
  We may also share your personal information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
            </p>

            <h3 className='font-sans text-xl lg:text-2xl text-blue mb-4'>
              Retention of information
            </h3>

            <p className='font-sans text-grey mb-4'>
              We only keep your personal information for as long as necessary to fulfil the purpose for which it was collected, or until you withdraw your consent for us to use it. You can withdraw your consent at any time.
            </p>

            <p className='font-sans text-grey mb-4'>
              If you wish to have any of your personal information removed, please email privacy@sequencebio.com to request deletion of your information. We may require further information to confirm your identity, which will only be used for that purpose.
            </p>

            <h3 className='font-sans text-xl lg:text-2xl text-blue mb-4'>
              Do not track
            </h3>

            <p className='font-sans text-grey mb-4'>
              Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.
            </p>

            <h3 className='font-sans text-xl lg:text-2xl text-blue mb-4'>
              Changes to this policy
            </h3>

            <p className='font-sans text-grey mb-4'>
              We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.  We strongly encourage you to refer to this Privacy Policy often for the latest information about our privacy and security practices.
            </p>

            <h3 className='font-sans text-xl lg:text-2xl text-blue mb-4'>
              Contact us
            </h3>

            <p className='font-sans text-grey mb-4'>
              If you have you any questions about our privacy practices, would like to access or change the personal information we have collected about you, or would like to make a complaint about how Sequence Bio handles your personal information, please contact Sequence Bio via email at privacy@sequencebio.com, or by mail using the contact details below
            </p>

            <p className='font-sans text-grey mb-4'>
              Sequence Bio<br />
              Attn: Privacy Officer<br />
              100 New Gower Street, Suite 370<br />
              St. John's, NL, A1C 6K3<br />
              Canada<br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Body;
