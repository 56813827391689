import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/privacy_policy/hero';
import Body from '../components/privacy_policy/body';

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title='Privacy policy' />
    <Hero />
    <Body />
  </Layout>
);

export default PrivacyPolicyPage;
